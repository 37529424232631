import React, { useEffect } from "react";
import styles from "./App.verification.module.scss";
// import "@gucci-private/gucci-react-components/dist/index.css";
import smoothscroll from "smoothscroll-polyfill";
import Loader from "./components/ui/loader/Loader";
import ThankYou from "./components/thankYou/ThankYou";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";

function App() {
  const featureToggle = useFeatureToggle();

  function forcePageReloadWhenCached(event) {
    if (event.persisted) {
      window.location.reload();
    }
  }

  useEffect(() => {
    smoothscroll.polyfill();
    window.addEventListener("pageshow", forcePageReloadWhenCached);

    return () => window.removeEventListener("pageshow", forcePageReloadWhenCached);

  },[]);

  if (!featureToggle) {
    return (
      <>
        <div
          style={{
            minHeight: "100vh"
          }}
        >
          <Loader/>
        </div>
      </>
    );
  }

  return (
    <>
      <main className={styles.thankYouContainer}>
        <section>
          <ThankYou />
        </section>
      </main>
    </>
  );
}

export default App;
