import React, { useEffect, useState } from "react";
import { getUser } from "../../api/idpUserServiceApi";
import {  useAsync } from "../../common/customHooks";
import { useCheckoutContext, useLocale } from "../../common/global";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";
import {
  Features,
  ProfileStatusType
} from "../../common/constants";
import { InformativeText } from "../ui/typography";
import styles from "./ThankYou.module.scss";
import { GTMGATechEventsPusher, GTMMyAccountOrderConfirmationEventsPusher } from "../../common/analytics";
import { objectToQueryParams, redirectToLocalUrl } from "../../common/utils";

function ThankYou() {
  const locale = useLocale();
  const { getToggle } = useFeatureToggle();
  const isFeatureRegisterAfterCheckoutOn = getToggle(Features.REGISTER_AFTER_CHECKOUT);
  const { gucciEmail, gucciTitle, gucciGender, gucciFirstname, gucciLastname, gucciOrderguid } = useCheckoutContext();
  const { execute: executeGetUser, value: userResponse } = useAsync(getUser, false);
  const [profileStatus, setProfileStatus] = useState(null);
  const reviewProfileQueryParams = objectToQueryParams({
    email : gucciEmail,
    isCheckout: true,
    ...(gucciTitle) && {
      title: gucciTitle
    },
    ...(gucciGender) && {
      gender: gucciGender
    },
    firstName : gucciFirstname,
    lastName: gucciLastname,
    orderGUID: gucciOrderguid,
    returnURI: "/"
  });

  const handleRegisterButtonClick = () => {
    GTMMyAccountOrderConfirmationEventsPusher({
      label: "click on create my gucci account"
    });
    redirectToLocalUrl(`/access/reviewProfile?${reviewProfileQueryParams}`, locale.baseUrl);
  };

  const handleSocialReviewProfileButtonClick = () => {
    GTMMyAccountOrderConfirmationEventsPusher({
      // TODO: is it just google? or we need to put other social providers?
      label: "click on complete your Google profile"
    });
    redirectToLocalUrl(`/access/socialReviewProfile?${reviewProfileQueryParams}`, locale.baseUrl);
  };

  useEffect(() => {
    if (gucciEmail) {
      executeGetUser(gucciEmail, locale.country);
    }
  }, []);

  useEffect(() => {
    if (profileStatus != null) {
      GTMGATechEventsPusher({
        action: "emailVerification.thankYou.profileStatus",
        label: profileStatus
      });
    }
  }, [profileStatus]);

  useEffect(() => {
    if (userResponse) {
      if (userResponse.status === 200) {
        userResponse.json().then(res => {
          setProfileStatus(res.status);
        });
      } else if (userResponse.status === 404) {
        setProfileStatus(ProfileStatusType.NEW);
      }
    }
  }, [userResponse]);

  return (
    <div data-testid="thank-you">
      {profileStatus === ProfileStatusType.NEW && isFeatureRegisterAfterCheckoutOn &&
      <>
        <div className={styles.informativeText}>
          <InformativeText className={`${styles.confirmationText} is-text-light`}>{locale.messages.ORDER_ACCOUNT}</InformativeText>
          <button data-testid="register-button" className={styles.callToAction} onClick={handleRegisterButtonClick}>{locale.labels.CREATE_ACCOUNT}</button>
        </div>
      </>}
      {profileStatus === ProfileStatusType.SOCIAL_NEW &&
      <>
        <div className={styles.informativeText}>
          <InformativeText  className={`${styles.confirmationText} is-text-light`}>{locale.messages.COMPLETE_REVIEW_PROFILE}</InformativeText>
          <button data-testid="social-review-profile-button" className={styles.callToAction} onClick={handleSocialReviewProfileButtonClick}>
            {locale.labels.COMPLETE_YOUR_PROFILE}
          </button>
        </div>
      </>
      }
    </div>
  );
}

export default ThankYou;
