import "core-js";
import "unfetch/polyfill/index";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { getLocalization, LocaleContext, getCheckoutUserDetails, UserDataContext } from "./common/global";
import { FeatureToggleContext } from "@gucci-private/feature-toggle-js";
import { MODAL_PARENT_ID, APP_PARENT_ID, featuresList } from "./common/constants";
import App from "./App.verification.js";

window.SignIn = {
  initVerification({ node }) {

    if (!node) {
      return;
    }

    const appElement = document.createElement("div");
    const modalElement = document.createElement("div");
    modalElement.setAttribute("id", MODAL_PARENT_ID);
    appElement.setAttribute("id", APP_PARENT_ID);

    node.appendChild(appElement);
    node.appendChild(modalElement);

    const localization = getLocalization();

    ReactDOM.render(
      <LocaleContext.Provider value={localization}>
        <FeatureToggleContext.Provider environment={process.env.REACT_APP_ENVIRONMENT} features={featuresList}
          variant={localization.country}>
          <UserDataContext.Provider value={getCheckoutUserDetails(node)}>
            <App/>
          </UserDataContext.Provider>
        </FeatureToggleContext.Provider>
      </LocaleContext.Provider>,
      appElement
    );
  },
  ...(window.SignIn || {})
};

window.SignIn.initVerification({
  node:document.getElementById("root")
});
